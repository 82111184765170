 /* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // found

        // $.each(foundin, function(value){
        //   var updated = $(this).val().replace('optgroup-','');
        //   $(this).nextUntil('option:contains("endoptgroup")').wrapAll('<optgroup label="'+updated+'"></optgroup>');
        // });
        // $('#some_select option:contains("optgroup-")').remove();
        // $('#some_select option:contains("endoptgroup")').remove();

        /* get device width */

        /*============================================================================
          Sticky nav
        ==============================================================================*/

        $(function() {
          $(window).scroll(function() {
            var header = ('.banner');
            var scrollTop = $(window).scrollTop();

            if ( scrollTop > 0 ){
              $(header).addClass('sticky');
              // console.log(scrollTop);
            } else {
              $(header).removeClass('sticky');
            }
          });
        });

        /*============================================================================
          STICKY ADDRESS
        ==============================================================================*/

        $(function() {
          var userDeviceWidth = $(window).width();
          var addressTop;
            if ( userDeviceWidth <= 767 ) {
              addressTop = 325;
            } else {
              addressTop = 425;
            }
          $(window).scroll(function() {
            var scrollTop = $(window).scrollTop();

            if ( scrollTop > addressTop ){
              $('body').addClass('has-sticky');
              // console.log(scrollTop);
            } else {
              $('body').removeClass('has-sticky');
            }
          });
        });

        /*============================================================================
          Menu toggle
        ==============================================================================*/

        function toggleNav() {
          var menuToggle = ('.menu-toggle');
          var navSecondary = ('.nav-secondary');
          var banner = ('.banner');
          var menuOverlay = ('.menu-overlay');
          var homeVideo = document.getElementById("home-bg-video");
          // var placeHolder = searchField.attr('placeholder', 'Type to search properties');

          $(menuToggle).click(function() {
            if ( $(banner).hasClass('nav-toggled') ) {
              // homeVideo.play();
              $(banner).removeClass('nav-toggled');
              $('body').removeClass('u-no-scroll has-overlay');
            } else {
              // homeVideo.pause();
              $(banner).addClass('nav-toggled');
              $('body').addClass('u-no-scroll has-overlay');
              $(this).focus();
            }
          });

          $(menuOverlay).click(function() {
            if ( $(banner).hasClass('nav-toggled') ) {
              $(banner).removeClass('nav-toggled');
              $('body').removeClass('u-no-scroll has-overlay');
              // homeVideo.play();
            }
          });
        }
        toggleNav();

        /*============================================================================
          Search toggle
        ==============================================================================*/

        function toggleSearch() {
          var searchToggle = ('.search-toggle');
          var searchForm = ('.search-bar');
          var closeSearch = ('.close-search');
          var searchField = ('.search-field');
          var banner = ('.banner');
          var homeVideo = document.getElementById("home-bg-video");

          $(searchToggle).click(function() {
            if ( $(searchForm).hasClass('search-toggled') ) {
              $(searchForm).removeClass('search-toggled');
              // $('body').removeClass('u-no-scroll has-overlay');
            } else {
              $(searchForm).addClass('search-toggled');
              $(banner).removeClass('nav-toggled');
              $(searchField).attr('placeholder', 'Type to search properties');
              $(searchField).focus();
              $('body').removeClass('u-no-scroll has-overlay');
            }
          });

          $(closeSearch).click(function() {
            if ( $(searchForm).hasClass('search-toggled') ) {
              $(searchForm).removeClass('search-toggled');
              $(searchToggle).focus();
              $(searchField).val('');
            }
          });

        }
        toggleSearch();

        /*============================================================================
          Scroll to animation
        ==============================================================================*/

        /* https://css-tricks.com/snippets/jquery/smooth-scrolling/ */
        function scrollToAnchor() {
          $('a[href*="#"]:not([href="#"])').on('click', function() {
            if ( location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') &&
             location.hostname === this.hostname &&
             !$(this).hasClass("no-move") ) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 700);
              }
                return false;
            }
          });
        }
        scrollToAnchor();

        /*============================================================================
          #sticky anschors
        ==============================================================================*/

          function stickyAnchors() {
            $(window).scroll(function() {
            var scrollPos = $(window).scrollTop();
              $('.menu-fixed__scrolling ul li a').each(function () {
                  var currLink = $(this);
                  var refElement = $(currLink.attr("href"));
                  var offsetElement = refElement.position().top - 375;
                  if (offsetElement <= scrollPos && offsetElement + refElement.height() > scrollPos) {
                    // console.log(offsetElement);
                      $('.menu-fixed__scrolling a').removeClass('active');
                      currLink.addClass('active');
                  }
                  else {
                      currLink.removeClass('active');
                  }
              });
            });
          }
          stickyAnchors();

          //bio

          function toggleBio() {
            var toggleBio = $('.leader');
            var leaderBio = $('.leadership-popup');
            var leaderBioContent = $('.leadership-bio');
            var closeBio = $('.close-overlay');
            var closeEverywhere = $('.leadership-wrapper');

            toggleBio.on('click', function() {
              clicked_el = this;
              var bioContent = $(this).find(leaderBio);
              var bioContentInfo = bioContent.html();
              // console.log(leaderBio);

              $('body').addClass('has-info-overlay');
              $(leaderBioContent).html(bioContentInfo);
              $(closeBio).focus();
            });

              $(closeBio).on('click', function() {
                $('body').removeClass('has-info-overlay');
                $(clicked_el).focus();
              });

              $(closeEverywhere).on('click', function() {
                $('body').removeClass('has-info-overlay');
                $(clicked_el).focus();
              });

              $(closeEverywhere).keyup(function(event){
                if (event.keyCode === 27) {
                  // Close the modal/menu
                  $('body').removeClass('has-info-overlay');
                  $(clicked_el).focus();
                }
              });
            }
            toggleBio();

          function toggleFilter() {
            var filterToggle = $('.filter-toggle');
            var filterDropdown = $('.filter-dropdown');

            $('.current-selection').text($('.filter-dropdown .active a').text());

            $(filterToggle).click(function(e) {
              e.preventDefault();
              if ( $(filterDropdown).is(':visible') ) {
                  $(filterDropdown).slideUp();
                  $(filterDropdown).removeClass('droptoggled');
                  $(filterToggle).removeClass('toggled');
                  // Waypoint.refreshAll();
              } else {
                  $(filterDropdown).slideDown();
                  $(filterDropdown).addClass('droptoggled');
                  $(filterToggle).addClass('toggled');
                  // Waypoint.refreshAll();
              }

              $('.current-selection').text($('.filter-dropdown .active a').text());
            });
          }
          toggleFilter();


          function toggleAccordion() {
            var accordionToggle = $('.u-accordion-toggle'),
                accordionPanel = $('.u-accordion-panel');

            $(accordionToggle).each(function() {
              var panel = $(this).next(accordionPanel);
              // console.log(panel);

              $(this).click( function() {
                if ( panel.is(':visible') ) {
                  $(this).removeClass('toggled');
                  $(panel).slideUp();
                } else {
                  $(this).addClass('toggled');
                  $(panel).slideDown();
                }
              });
            });
          }
          toggleAccordion();

          /*============================================================================
            #Tab switcher - map/grid view
          ==============================================================================*/

//BRING FORWARD
          function tabSwitcher() {

            var $tab_switcher = $('.js-tab-switch');
            var $tab_content = $('.tab-content');
            var filterToggle = $('.filter-toggle');
            var filterDropdown = $('.filter-dropdown');

            $tab_switcher.on('click', function(e) {
              e.preventDefault();
              var $self = $(this);
              var link = $self.attr('href');
              var $target = $(link);

              if (!$self.hasClass('is-active')) {
                $tab_switcher.removeClass('is-active');
                $self.addClass('is-active');
              }

              if (!$target.hasClass('is-active')) {
                $tab_content.removeClass('is-active');
                $target.addClass('is-active');
                // console.log('tabbed');
              }

              if ( $self.hasClass('map-view') ) {
                window.location.hash = '#map-view';
              } else {
                window.location.hash = '#grid-view';
              }

              $(filterDropdown).slideUp();
              $(filterToggle).removeClass('toggled');
              // Waypoint.refreshAll();

            });

          }
          tabSwitcher();

          function activeTab() {
            if ( window.location.hash === '#map-view' ) {
              $('.js-tab-switch').removeClass('is-active');
              $('.map-view').addClass('is-active');
              $('.tab-content-list').removeClass('is-active');
              $('.tab-content-map').addClass('is-active');
              // console.log('test');
            } else {
              $('.js-tab-switch').removeClass('is-active');
              $('.grid-view').addClass('is-active');
              $('.tab-content-map').removeClass('is-active');
              $('.tab-content-list').addClass('is-active');
            }
          }
          activeTab();

          function toggleInfo() {
            var filterToggle = $('.info-toggle');
            var filterDropdown = $('.single__property-details');
            $(filterToggle).click(function() {
              if ( $(filterDropdown).is(':visible') ) {
                  $(filterDropdown).slideUp();
                  $(filterToggle).removeClass('toggled');
                  // Waypoint.refreshAll();
              } else {
                  $(filterDropdown).slideDown();
                  $(filterToggle).addClass('toggled');
                  // Waypoint.refreshAll();
              }
            });
          }
          toggleInfo();

        /*============================================================================
          # Animations + Waypoints
        ==============================================================================*/

        var slideUp = $('.slide-up').waypoint({
          handler: function(direction) {
            if (direction === 'down'){
              $(this.element).addClass('go');
            } else {
              $(this.element).removeClass('go');
            }
          },
          offset: '100%'
        });

        var slideParallax = $('.slide-up-front').waypoint({
          handler: function(direction) {
            if (direction === 'down'){
              $(this.element).addClass('go');
            } else {
              $(this.element).removeClass('go');
            }
          },
          offset: '70%'
        });

        var slideIn = $('.slide-in').waypoint({
          handler: function(direction) {
            if (direction === 'down'){
              $(this.element).addClass('go');
            } else {
              $(this.element).removeClass('go');
            }
          },
          offset: '86%'
        });

        var animate = $('.anim').waypoint({
          handler: function(direction) {
            if (direction === 'down'){
              $(this.element).addClass('go');
            } else {
              $(this.element).removeClass('go');
            }
          },
          offset: '100%'
        });

          function elParallax() {
            $(window).scroll(function() {
              var userDeviceWidth = $(window).width();
              if ( userDeviceWidth >= 1024 ) {
                $('.parallax-element').each(function() {
                  var speed = 0.15;
                  var elPosition = $(this).offset().top;
                  // console.log(elPosition);
                  var windowYOffset = (window.pageYOffset);
                    // console.log('window' + windowYOffset);
                  var elOffset = (elPosition - windowYOffset);
                    // console.log(elOffset);
                  var elMargin = (elOffset * speed) + "px";
                  // console.log(elMargin);
                  $(this).css({'transform':'translateY(' + elMargin});
                });
                }
              });

          }
          elParallax();


          function parallax() {
            var speed = 0.1;
            $(window).scroll(function() {
              var windowYOffset = (window.pageYOffset);
              var bgPosition = "-" + (windowYOffset * speed) + "px";
            $('.parallax-feature').each(function() {
                $(this).css({top:bgPosition});
                // console.log(bgPosition);
              });
            });
          }
          parallax();

        /*============================================================================
          # Properties ALL Map
        ==============================================================================*/

        //INFOBOX OPTIONS
        var myOptions = {
          disableAutoPan: false,
          maxWidth: 0,
          pixelOffset: new google.maps.Size(50, -310),
          zIndex: null,
          boxStyle: {
            background: "#000000",
            opacity: 1,
            width: "310px",
            height: "379px",
            margin: 0,
            padding: 0,
            "box-shadow": "1px 1px 3px #CED1D9"
           },
          closeBoxMargin: "12px",
          closeBoxURL: "/app/themes/peterson/assets/images/marker-close.png",
          infoBoxClearance: new google.maps.Size(1, 1),
          isHidden: false,
          pane: "floatPane",
          enableEventPropagation: false
        };

        var boxText = document.createElement("div");
        boxText.style.cssText = "border: 0 solid black; margin: 0; background: #000000; color: #ffffff; padding: 0;";


        myOptions.content = boxText;
        var ib = new InfoBox(myOptions);

        function property_map( $el ) {

          // var
          var markers = $el.find('.marker');
          /*
            *  add_marker
            *
            *  This function will add a marker to the selected Google Map
            *
            *  @type  function
            *  @date  8/11/2013
            *  @since 4.3.0
            *
            *  @param $marker (jQuery element)
            *  @param map (Google Map object)
            *  @return  n/a
          */

          // Add markers to the map
          function add_marker( $marker, map ) {
              // var
              var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
              var filterCategory = $marker.attr('data-filter');
              // console.log(filterCategory);

              // create marker
              var marker = new google.maps.Marker({
                position: latlng,
                map: map,
                icon: '/app/themes/peterson/assets/images/map-marker.png',
                scale: 1,
                category: filterCategory,
              });

              // add to array
              map.markers.push( marker );


            function showPanel() {
              // console.log( 'show panel' );
              var panel = $('.panel'),
                markerContent = $marker.html();
              $(panel).html(markerContent);
              // console.log( panel );
              $(panel).addClass('show-panel');
              // document.createElement('div.panel');
            }

            function hidePanel() {
              var panel = $('.panel');
              $(panel).html('');
              $(panel).removeClass('show-panel');
            }

            google.maps.event.addListener(marker, "click", function() {
              ib.close();
              boxText.innerHTML = $marker.html();

              var userDeviceWidth = $(window).width();
                if ( userDeviceWidth < 1024 ) {
                  showPanel();
                } else {
                  ib.open(map, marker);
                  // map.setZoom(6);
                }
              //ib.open(map, marker);
              map.panTo(ib.getPosition());
              marker.setIcon('/app/themes/peterson/assets/images/map-marker-selected.png');
              map.setCenter(marker.getPosition());

            });


            google.maps.event.addListener(map, 'click', function() {
              if(ib) {
                ib.close();
              }

            });

            google.maps.event.addListener(map, 'dragstart', function() {
              hidePanel();
            });


          } //end add_marker

          // Create a new StyledMapType object, passing it an array of styles,
          // and the name to be displayed on the map type control.
          var styles =
                     [
                            {
                                "featureType": "water",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#e9e9e9"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "featureType": "landscape",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "geometry.fill",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "geometry.stroke",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "featureType": "road.arterial",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "featureType": "road.local",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#dedede"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#333333"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "transit",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "featureType": "administrative",
                                "elementType": "geometry.fill",
                                "stylers": [
                                    {
                                        "color": "#fefefe"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "featureType": "administrative",
                                "elementType": "geometry.stroke",
                                "stylers": [
                                    {
                                        "color": "#fefefe"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            }
                        ];

             // stylish map
            var styledMap = new google.maps.StyledMapType(styles, {name: "Styled Map"});

            // vars
            var args = {
              center: new google.maps.LatLng(40.895077, -97.138451),
              zoom: 4,
              // maxZoom: 11,
              minZoom: 3,
              scaleControl: false,
              streetViewControl: false,
              overviewMapControl: false,
              draggable: true,
              disableDoubleClickZoom: false,
              mapTypeControlOptions: {
                mapTypeIds: ['roadmap',
                        'map_style'],
                position: google.maps.ControlPosition.TOP_RIGHT
              },
              panControl: true,
              panControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
              },
              zoomControl: true,
              zoomControlOptions: {
                style: google.maps.ZoomControlStyle.LARGE,
                position: google.maps.ControlPosition.TOP_RIGHT
              },
              scrollwheel: false,
              mapTypeControl: false,
            };

            // create map
            var map = new google.maps.Map( $el[0], args);

            // style map
            map.mapTypes.set('map_style', styledMap);
            map.setMapTypeId('map_style');

            // add a markers reference
            map.markers = [];

            //add markers
            markers.each(function(){
              add_marker( $(this), map );
            });

            // return
            return map;

          } //end property_map function


          /*
          *  document ready
          *
          *  This function will render each map when the document is ready (page has loaded)
          *
          *  @type  function
          *  @date  8/11/2013
          *  @since 5.0.0
          *
          *  @param n/a
          *  @return  n/a
          */
          // global var
          var map = null;

          $(document).ready(function(){

            // show marker infoboxes after map has loaded (hidden with css to avoid showing before map loads).
            // var $markerBox = $('.marker__infobox');

            // $markerBox.each( function() {
            //    $markerBox.addClass('infobox_visible');
            // });

            $('.property-map').each(function(){
              // create map
              map = property_map( $(this) );

              var userDeviceWidth = $(window).width();
                if ( userDeviceWidth < 1024 ) {
                  map.setZoom (1);
              }
            });

          });


          function filterMarkers( category ) {
            var markers1 = map.markers;
            // console.log(markers1);
            ib.close(map);
            for(i = 0; i < markers1.length; i++) {
              // console.log(markers1[i]);
              marker = markers1[i];
              // If is same category or category not picked
              if (marker.category.includes( category ) ) {
                marker.setVisible(true);
                marker.setIcon('/app/themes/peterson/assets/images/map-marker.png');
                // console.log(marker);
              }
              // Categories don't match
              else {
                marker.setVisible(false);
                marker.setIcon('/app/themes/peterson/assets/images/map-marker.png');
              }
            }

            map.setZoom( 4 );
            map.setCenter( new google.maps.LatLng(40.895077, -97.138451) );

          }

          function showAllMarkers() {
            var markers1 = map.markers;
            // console.log(markers1);
            ib.close();

            for(i = 0; i < markers1.length; i++) {
              // console.log(markers1[i]);
              marker = markers1[i];
                // If is same category or category not picked
                marker.setVisible(true);
                marker.setIcon('/app/themes/peterson/assets/images/map-marker.png');
            }

            map.setZoom( 4 );
            map.setCenter( new google.maps.LatLng(40.895077, -97.138451) );

          }



          //end map

        /*============================================================================
          # Filter
        ==============================================================================*/

        function filterProperties() {
          var filterLink = $('.filter-link');
          var filterToggle = $('.filter-toggle');
          var filterDropdown = $('.filter-dropdown');
          var property = $('.property');

          $('.filter-link:not(.filter-all)').click(function( e ) {
            e.preventDefault();
            // console.log( 'Clicked Link' );
            var filter = $(this).data('filter');
            // var filterL = $(this).data('filter').toLowerCase();
            // console.log(filterL);

            $(filterLink).parent().removeClass('active');
            $(this).parent().addClass('active');
            // window.location.hash = filter;

            if ( $(filterDropdown).is(':visible') ) {
              $(filterDropdown).slideUp();
              $(filterToggle).removeClass('toggled');
            }

            $(property).each(function() {
              if ( $(this).hasClass(filter) ) {
                $(this).removeClass('anim');
                $(this).show();
              } else {
                $(this).hide();
              }
            });
            $('.current-selection').text($('.filter-dropdown .active a').text());
            filterMarkers( filter );
            // Waypoint.refreshAll();

          }); //end filterLink.click

          function activeFilter() {
            var filterLinkP = $('.filter-link');
            var filters = $(filterLinkP).toArray();
            var filterP = '';

            // console.log( filters.length );

            $(filters).each(function() {
              var filterP = $(this).data('filter');
              var filterO = '#' + filterP;

              // console.log( filterO );

              if ( window.location.hash === filterO ) {
                // $('.js-tab-switch').removeClass('is-active');
                $(filterLinkP).parent().removeClass('active');
                $(this).parent().addClass('active');
                $(property).each(function() {
                  if ( $(this).hasClass(filterP) ) {
                    $(this).removeClass('anim');
                    $(this).show();
                  } else {
                    $(this).hide();
                  }
                });
                filterMarkers( filterO );
              } else {
                // $('.js-tab-switch').removeClass('is-active');
                // $('.grid-view').addClass('is-active');
                // $('.tab-content-map').removeClass('is-active');
                // $('.tab-content-list').addClass('is-active');
              }

            });


          }
          activeFilter();

          //Filter show all
          $('.filter-all').click(function( event ) {
            event.preventDefault();

            $(filterLink).parent().removeClass('active');
            $(this).parent().addClass('active');
            // window.location.hash = '';

            if ( $(filterDropdown).is(':visible') ) {
              $(filterDropdown).slideUp();
              $(filterToggle).removeClass('toggled');
              // Waypoint.refreshAll();
            }

            $(property).each(function() {
              $(this).removeClass('anim');
              $(this).show();
              // $(this).addClass('go');
            });
            showAllMarkers();

            $('.current-selection').text($('.filter-dropdown .active a').text());
          }); //end filterALL.click
        }
        filterProperties();


        /*======================================================
         # Back button
        ========================================================*/


        /*============================================================================
          # Property SINGLE Map
        ==============================================================================*/

          (function($) {

                        /*
            *  add_marker
            *
            *  This function will add a marker to the selected Google Map
            *
            *  @type  function
            *  @date  8/11/2013
            *  @since 4.3.0
            *
            *  @param $marker (jQuery element)
            *  @param map (Google Map object)
            *  @return  n/a
            */

            function add_marker( $marker, map ) {

              // var
              var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

              // create marker
              var marker = new google.maps.Marker({
                position  : latlng,
                map     : map,
                icon: '/app/themes/peterson/assets/images/map-marker.png',
                url: $('#markerUrl').val(),
                scale: 1,
                clickable: true,
              });

              google.maps.event.addListener(marker, 'click', function() {
                // window.location.href = marker.url;
                window.open(marker.url, '_blank');
              });

              // add to array
             map.markers.push( marker );

              // if marker contains HTML, add it to an infoWindow
              if( $marker.html() )
              {
                // create info window
                var infowindow = new google.maps.InfoWindow({
                  content   : $marker.html()
                });

                // show info window when marker is clicked
                google.maps.event.addListener(marker, 'click', function() {

                  infowindow.open( map, marker );

                });
              }

            }

            /*
            *  center_map
            *
            *  This function will center the map, showing all markers attached to this map
            *
            *  @type  function
            *  @date  8/11/2013
            *  @since 4.3.0
            *
            *  @param map (Google Map object)
            *  @return  n/a
            */

            function center_map( map ) {

              // vars
              var bounds = new google.maps.LatLngBounds();

              // loop through all markers and create bounds
              $.each( map.markers, function( i, marker ){

                var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

                bounds.extend( latlng );

              });

              // only 1 marker?
              if( map.markers.length === 1 )
              {
                // set center of map
                  map.setCenter( bounds.getCenter() );
                  map.setZoom( 14 );
              }
              else
              {
                // fit to bounds
                map.fitBounds( bounds );
              }

            }

            /*
            *  new_map
            *
            *  This function will render a Google Map onto the selected jQuery element
            *
            *  @type  function
            *  @date  8/11/2013
            *  @since 4.3.0
            *
            *  @param $el (jQuery element)
            *  @return  n/a
            */

            function new_map( $el ) {

              // var
              var $markers = $el.find('.marker');


              // vars
              var args = {
                zoom    : 12,
                minZoom : 9,
                maxZoom : 18,
                scaleControl: false,
                streetViewControl: false,
                overviewMapControl: false,
                gestureHandling: 'cooperative',
                disableDoubleClickZoom: false,
                center    : new google.maps.LatLng(0, 0),
                mapTypeId : google.maps.MapTypeId.ROADMAP,
                mapTypeControlOptions: {
                mapTypeIds: ['roadmap',
                        'map_style'],
                position: google.maps.ControlPosition.TOP_RIGHT
                },
                panControl: true,
                panControlOptions: {
                  position: google.maps.ControlPosition.TOP_RIGHT
                },
                zoomControl: true,
                zoomControlOptions: {
                  style: google.maps.ZoomControlStyle.LARGE,
                  position: google.maps.ControlPosition.TOP_RIGHT
                },
                scrollwheel: false,
                mapTypeControl: false,
                styles : [
                            {
                                "featureType": "water",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#e9e9e9"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "featureType": "landscape",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "geometry.fill",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "geometry.stroke",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "featureType": "road.arterial",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "featureType": "road.local",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#dedede"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#333333"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "transit",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "featureType": "administrative",
                                "elementType": "geometry.fill",
                                "stylers": [
                                    {
                                        "color": "#fefefe"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "featureType": "administrative",
                                "elementType": "geometry.stroke",
                                "stylers": [
                                    {
                                        "color": "#fefefe"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            }
                        ]
              };


              // create map
              var map = new google.maps.Map( $el[0], args);


              // add a markers reference
              map.markers = [];


              // add markers
              $markers.each(function(){

                  add_marker( $(this), map );

              });


              // center map
              center_map( map );


              // return
              return map;

            }

            /*
            *  document ready
            *
            *  This function will render each map when the document is ready (page has loaded)
            *
            *  @type  function
            *  @date  8/11/2013
            *  @since 5.0.0
            *
            *  @param n/a
            *  @return  n/a
            */
            // global var
            var map = null;

            $(document).ready(function(){

              $('.acf-map').each(function(){

                // create map
                map = new_map( $(this) );

              });

            });

            })(jQuery);

              //Please note, the DOM should be ready
            //   Barba.Pjax.start();

            //   var FadeTransition = Barba.BaseTransition.extend({
            //   start: function() {
            //     /**
            //      * This function is automatically called as soon the Transition starts
            //      * this.newContainerLoading is a Promise for the loading of the new container
            //      * (Barba.js also comes with an handy Promise polyfill!)
            //      */

            //     // As soon the loading is finished and the old page is faded out, let's fade the new page
            //     Promise
            //       .all([this.newContainerLoading, this.fadeOut()])
            //       .then(this.fadeIn.bind(this));
            //   },

            //   fadeOut: function() {
            //     /**
            //      * this.oldContainer is the HTMLElement of the old Container
            //      */
            //      $('.loader').addClass('loading');

            //     return $(this.oldContainer).animate({ opacity: 0 }).promise();
            //   },

            //   fadeIn: function() {
            //     // *
            //     //  * this.newContainer is the HTMLElement of the new Container
            //     //  * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
            //     //  * Please note, newContainer is available just after newContainerLoading is resolved!
            //     $('.loader').removeClass('loading');

            //     var _this = this;
            //     var $el = $(this.newContainer);

            //     $(this.oldContainer).hide();

            //     $el.css({
            //       visibility : 'visible',
            //       opacity : 0
            //     });

            //     $el.animate({ opacity: 1 }, 400, function() {
            //       /**
            //        * Do not forget to call .done() as soon your transition is finished!
            //        * .done() will automatically remove from the DOM the old Container
            //        */

            //       _this.done();
            //     });
            //   }
            // });

            // /**
            //  * Next step, you have to tell Barba to use the new Transition
            //  */

            // Barba.Pjax.getTransition = function() {
            //   /**
            //    * Here you can use your own logic!
            //    * For example you can use different Transition based on the current page or link...
            //    */

            //   return FadeTransition;
            // };

            // // if (window.ADMIN_BAR_VISIBLE) {
            // //   Barba.Pjax.preventCheck = function() {
            // //     return false;
            // //   };
            // // }

            // Barba.Dispatcher.on('transitionCompleted', function(currentStatus, oldStatus, container) {
            //   Waypoint.enableAll();
            //   toggleNav();
            //   toggleSearch();
            //   scrollToAnchor();
            //   stickyAnchors();
            //   toggleBio();
            //   toggleFilter();
            //   toggleInfo();
            //   filterProperties();
            //   tabSwitcher();
            // });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // object-fit detection ie

        if ( ! Modernizr.objectfit ) {
          $('.u-ofit').each(function () {
            var $container = $(this),
                imgUrl = $container.find('img').prop('src');
            if (imgUrl) {
              $container
                .css('backgroundImage', 'url(' + imgUrl + ')')
                .addClass('compat-object-fit');
            }
          });
        }

        $('[data-property-toggle]').on('click', function(e) {
          e.preventDefault();

          $('.tab-toggle').removeClass('active');
          $(this).addClass('active');

          $('.tab').fadeOut();
          $('[data-property-tab="' + $(this).data('property-toggle') + '"]').fadeIn();
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        function pauseVideo() {
          var userDeviceWidth = $(window).width();
          console.log(userDeviceWidth);

          if ( userDeviceWidth > 767 ) {

            var menuToggle = ('.menu-toggle');
            var banner = ('.banner');
            var homeVideo = document.getElementById("home-bg-video");
            var menuOverlay = ('.menu-overlay');
            var homeBg = ('.home-bg');

            $(menuToggle).click(function() {
              if ( $(banner).hasClass('nav-toggled') ) {
                // homeVideo.pause();
              } else {
                // homeVideo.play();
              }
            });

            $(menuOverlay).click(function() {
              if ( $(banner).hasClass('nav-toggled') ) {
                // homeVideo.pause();
              } else {
                // homeVideo.play();
              }
            });

            //pause video on scroll

            // $(window).scroll(function() {

            // console.log(elementTop);

            $(window).scroll(function() {
              var wrapElement = $('.wrap');
              var elementTop = $(wrapElement).offset().top;
              var scrollTop = $(window).scrollTop();
              // console.log(scrollTop);
              if( scrollTop > elementTop || $('.banner').hasClass('nav-toggled') ) {
                // homeVideo.pause();
                $(homeVideo).css({ 'position':'relative' });
                $(homeBg).css({ 'position':'relative' });
                // console.log('pause');
              } else {
                // homeVideo.play();
                $(homeVideo).css({ 'position':'fixed' });
                $(homeBg).css({ 'position':'fixed' });
                // console.log('play');
              }
            });
          }

        }
        pauseVideo();

        $(window).scroll(function () {
          var wrapElement = $('.wrap');
          var elementTop = $(wrapElement).offset().top;
          var scrollTop = $(window).scrollTop();
          // console.log(scrollTop);
          if (scrollTop > elementTop || $('.banner').hasClass('nav-toggled')) {
            $('.banner').addClass('home-sticky');
            // console.log('pause');
          } else {
            $('.banner').removeClass('home-sticky');
            // console.log('play');
          }
        });

        /*============================================================================
          Scroll Magic
        ==============================================================================*/

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

    // About us page, note the change from about-us to about_us.
    'contact_us': {
      init: function() {
        // JavaScript to be fired on the about us page

        /*============================================================================
        CONTACT FORM 7 CUSTOM investment optgroup
        ==============================================================================*/
        var foundin = $('.menu-614 select option:contains("optgroup-")');
        $.each(foundin, function(value){
          var updated = $(this).val().replace('optgroup-','');
          $(this).nextUntil('option:contains("endoptgroup")').wrapAll('<optgroup label="'+updated+'"></optgroup>');
        });
        $('.menu-614 select option:contains("optgroup-")').remove();
        $('.menu-614 select option:contains("endoptgroup")').remove();


      }
    },

    //commercial

    'commercial': {
      init: function() {

      } //end init

    } //end commercial

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
